import { Component, OnInit, ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import {CampagneModel} from 'src/app/model/campagne-model.model'
import {data} from 'src/app/model/apx-chartPieNetwork.model'
import {CampagneAndOfferFctService } from 'src/app/service/campagne-and-offer-fct.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {toast} from 'src/app/model/toast'
import { Router} from '@angular/router';


@Component({
  selector: 'app-campagne-selector',
  templateUrl: './campagne-selector.component.html',
  styleUrls: ['./campagne-selector.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css', '../../../assets/styles/campagne.css']
})
export class CampagneSelectorComponent implements OnInit {
  selected : any
  campaign : CampagneModel;
  allSelected : any
  userArray : any;
  tmp: string;
  chartOptions : any = data;
  toast = toast
  p: number = 1;
  total: number
  count: number = 25;

//////////////////////array qui est utilisé pour lister les influenceurs avec filtres///////////////////////////

  currentUserNetworks : any;
  userNetworksUnique : any;
  //////////////////////Boolean qui sert à afficher le tableau des influenceurs selectioné///////////////////////////

  sendOffer = false;
  //////////////////////array qui est utilisé pour lister les influencerus selectioné///////////////////////////

  arrayToSendOffer : any = [];
  //////////////////////valeur qui change celon le filtre///////////////////////////
  
  sizedata : any = []
  sizedataReach : any = []
  term: string;
  //////////////////////Boolean qui charge la page///////////////////////////
  campagneLoaded = false
  /////////////////////Variable ngx-chart///////////////////////////////
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showGridLines: boolean = false
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Statistiques';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Population';
  legendTitle: string = 'Date';
  noBarWhenZero: boolean = false;
  legendPosition: any = 'below';
  view: [number, number] = [135, 135];
  viewList: [number, number] = [250, 200];
  colorScheme = {
    domain: ['#006579', '#4bdbaa', "#cce386", "#e0de3d", "#f1b306", "#fc7304", "#ff3300", "#8d0000","#aa0033","#ff004c","#58013b","#7d008d", "#26008d"]
  };
/////////////////////////////////////////////////////////////////

////////////////////Attribute pour filtrer les influenceurs/////////////////////////////////////////////
 
  reach : number = 0
  platformeType : any
  country = new FormControl("");
  selector: FormGroup;
  city = new FormControl("");
  language = new FormControl("");
  secondaryLanguage= new FormControl("");
  topping = new FormControl("");
  Keywords = new FormControl([]);
  zipcodes = new FormControl("");

  userReady = false
  keywordTxt : any = "";
  zipcodeTxt : any = ""
  toppingList: string[] = ['fashion', 'beauty', 'health', 'food', 'homedeco', 'travel', 'automotive', 'tech', 'lifestyle', 'petcare'];

  countries: string[] = ["France", "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla", "Antigua & Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas"
    , "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia & Herzegovina", "Botswana", "Brazil", "British Virgin Islands"
    , "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia", "Congo", "Cook Islands", "Costa Rica"
    , "Cote D Ivoire", "Croatia", "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea"
    , "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon", "Gambia", "Georgia", "Germany", "Ghana"
    , "Gibraltar", "Greece", "Greenland", "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India"
    , "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos", "Latvia"
    , "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Mauritania"
    , "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia"
    , "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal"
    , "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia", "Rwanda", "Saint Pierre & Miquelon", "Samoa", "San Marino", "Satellite", "Saudi Arabia", "Senegal", "Serbia", "Seychelles"
    , "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea"
    , "Spain", "Sri Lanka", "St Kitts & Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan"
    , "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad & Tobago", "Tunisia"
    , "Turkey", "Turkmenistan", "Turks & Caicos", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay"
    , "Uzbekistan", "Venezuela", "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe"];

  languages: string[] = ["French", "Afrikaans", "Albanian", "Amharic", "Arabic", "Aramaic", "Armenian", "Assamese", "Aymara", "Azerbaijani", "Balochi",
    "Bamanankan", "Bashkort (Bashkir)", "Basque", "Belarusan", "Bengali", "Bhojpuri", "Bislama", "Bosnian", "Brahui", "Bulgarian", "Burmese",
    "Cantonese", "Catalan", "Cebuano", "Chechen", "Cherokee", "Croatian", "Czech", "Dakota", "Danish", "Dari", "Dholuo", "Dutch", "English",
    "Esperanto", "Estonian", "Éwé", "Finnish", "French", "Georgian", "German", "Gikuyu", "Greek", "Guarani", "Gujarati", "Haitian Creole",
    "Hausa", "Hawaiian", "Hawaiian Creole", "Hebrew", "Hiligaynon", "Hindi", "Hungarian", "Icelandic", "Igbo", "Ilocano", "Indonesian (Bahasa Indonesia)",
    "Inuit/Inupiaq", "Irish Gaelic", "Italian", "Japanese", "Jarai", "Javanese", "K’iche’", "Kabyle", "Kannada", "Kashmiri", "Kazakh", "Khmer", "Khoekhoe",
    "Korean", "Kurdish", "Kyrgyz", "Lao", "Latin", "Latvian", "Lingala", "Lithuanian", "Macedonian", "Maithili", "Malagasy", "Malay (Bahasa Melayu)", "Malayalam",
    "Mandarin (Chinese)", "Marathi", "Mende", "Mongolian", "Nahuatl", "Navajo", "Nepali", "Norwegian", "Ojibwa", "Oriya", "Oromo", "Pashto", "Persian",
    "Polish", "Portuguese", "Punjabi", "Quechua", "Romani", "Romanian", "Russian", "Rwanda", "Samoan", "Sanskrit", "Serbian", "Shona", "Sindhi", "Sinhala",
    "Slovak", "Slovene", "Somali", "Spanish", "Swahili", "Swedish", "Tachelhit", "Tagalog", "Tajiki", "Tamil", "Tatar", "Telugu", "Thai", "Tibetic languages",
    "Turkish", "Turkmen", "Ukrainian", "Urdu", "Uyghur", "Uzbek", "Vietnamese", "Warlpiri", "Welsh", "Wolof", "Xhosa", "Yakut", "Yiddish", "Yoruba", "Yucatec",
    "Zapotec", "Zulu"]

    revenueTotalSent = 0
    deliveryTotalSent = 0
  constructor(
    private service: ApiService, 
    private route: ActivatedRoute, 
    private fb: FormBuilder,
    public fctBasic: CampagneAndOfferFctService, 
    private cdRef:ChangeDetectorRef,
    public router: Router,
    private toastr: ToastrService ) { 
      Object.assign(this.chartOptions, { data });
    }

  ngOnInit(): void {
    this.service.getCampaign(this.route.snapshot.params.id).subscribe(campagne => {
      this.campaign = campagne
      this.campagneLoaded = true
      this.platformeType = campagne.platformeType
      this.service.getUserOnCampaignSelector(campagne.platformeType).subscribe(userSeleted => {
        this.userArray = userSeleted.users
        this.reach = userSeleted.reach
        console.log("userSeleted.networks ", userSeleted.networks)
        this.currentUserNetworks = userSeleted.networks
        this.userNetworksUnique = userSeleted.networks
        this.userReady = true;
        this.sizedata = [
          {
            "name": "",
            "value":  userSeleted.networks.length
          }
        ]
        this.sizedataReach = [
          {
            "name": "",
            "value":  this.reach
          }
        ]
        this.selector = this.fb.group({
          country : this.country,
          city : this.city,
          language: this.language,
          secondaryLanguage : this.secondaryLanguage,
          topping: this.topping,
          zipcodes: this.zipcodes,
          Keywords: this.Keywords,
        })
        this.cdRef.detectChanges();
        this.loopOnOffersToGetTotal(this.campaign.offerObject)
        this.cdRef.detectChanges();
      })
    })
  }

  loopOnOffersToGetTotal(offers: any){
    offers.forEach((offer : any) => {

      if (offer.status != "Rejected" && offer.status != "Cancelled"){
        this.revenueTotalSent = this.revenueTotalSent + offer.revenueTarget;
        this.deliveryTotalSent = this.deliveryTotalSent + offer.deliveryTarget;
        this.cdRef.detectChanges();
      }
    });
    this.cdRef.detectChanges();
  }

  setIndividualOffer(networks: any){
    this.arrayToSendOffer = []
    networks.deliveryTarget = 0;
    networks.prixUnitaire = 0;
    networks.revenueTarget = 0;
    this.arrayToSendOffer.push(networks)
    this.sendOffer = true
  }

  addInfluenceurOnArray(network: any){
    let index =  this.arrayToSendOffer.indexOf(network);
    if (index != -1) {
      this.arrayToSendOffer.splice(index, 1)
    } else {
      network.deliveryTarget = 0;
      network.prixUnitaire = 0;
      network.revenueTarget = 0;
      this.arrayToSendOffer.push(network);
    }
  }

  resetResearch() {
    window.location.reload();
}

async filterDuplicata(arr : any){
  return  arr.filter(function(elem: any, index: any, self: string | any[]) {
    return index === self.indexOf(elem);
  })
}

filterByValue(array: any[], string: string) {
  return array.filter((data) =>  JSON.stringify(data).toLowerCase().indexOf(string.toLowerCase()) !== -1);
}

filterByNumber(array: any[], number: number) {
  return array.filter(function(item) { 
    return item.influencerInfo.postcode === number; });
}


  loopOnArrayFilter(arr : any){
    var newArrUser: any[] = []
    arr.forEach((elem: any) => {
      let newArr = this.filterByValue(this.userNetworksUnique, elem)
      newArrUser = newArrUser.concat(newArr)
    });
    return newArrUser
  }

  loopOnArrayFilterNumber(arr : any){
    var newArrUser: any[] = []
    arr.forEach((elem: any) => {
      let newArr = this.filterByNumber(this.userNetworksUnique, elem)
      newArrUser = newArrUser.concat(newArr)
    });
    return newArrUser
  }

  async searchInfluenceur(){
    var newArr: any[] = []
    if (this.selector.value["country"] && this.selector.value["country"].length > 0){
      let arrGeo = this.loopOnArrayFilter(this.selector.value["country"])
      newArr = newArr.concat(arrGeo)
    }
    if (this.selector.value["city"] && this.selector.value["city"].length > 0){
      let arrmultiCity = this.loopOnArrayFilter(this.selector.value["city"])
      newArr = newArr.concat(arrmultiCity)
    }
    if (this.selector.value["language"] && this.selector.value["language"].length > 0){
      let arrLangugage = this.loopOnArrayFilter(this.selector.value["language"])
      newArr = newArr.concat(arrLangugage)
    }
    if (this.selector.value["secondaryLanguage"] && this.selector.value["secondaryLanguage"].length > 0){
      let arrLangugage2 = this.loopOnArrayFilter(this.selector.value["secondaryLanguage"])
      newArr = newArr.concat(arrLangugage2)
    }
    if (this.selector.value["topping"] && this.selector.value["topping"].length > 0){
      let topping = this.loopOnArrayFilter(this.selector.value["topping"])
      newArr = newArr.concat(topping)
    }
    if (this.selector.value["zipcodes"] && this.selector.value["zipcodes"].length > 0){
      let zipcodes = this.loopOnArrayFilterNumber(this.selector.value["zipcodes"])
      newArr = newArr.concat(zipcodes)
    }
    if (this.selector.value["Keywords"] && this.selector.value["Keywords"].length > 0){
      let Keywords = this.loopOnArrayFilter(this.selector.value["Keywords"])
      newArr = newArr.concat(Keywords)
    }
    let arrFinalize = await this.filterDuplicata(newArr)
    await this.finalizeupdatechart(arrFinalize)
  }

  async finalizeupdatechart(array : any){
    if (array.length == 0){
      this.currentUserNetworks = this.userNetworksUnique
    }
    this.currentUserNetworks = array
    let newjsonvalue = [{
      "name": "",
      "value":  array.length
    }]
    this.sizedata = newjsonvalue;
    this.cdRef.detectChanges();
  }

  showArrSelected(){
    if (this.arrayToSendOffer.length <= 0){
      this.toastr.warning(this.toast.campaignNotUserselect)
      return
    } else {
      this.sendOffer = true
      this.cdRef.detectChanges();
    }
}

 async  sendOfferForUser(networks: any, index: number){
  let offer = await this.fctBasic.initOffer(this.campaign, networks)
  if (offer != null){
      this.service.postOffer(offer).subscribe(offerPost => {
        this.toastr.success(this.toast.sendOffer);
        this.revenueTotalSent = this.revenueTotalSent + parseInt(networks.revenueTarget);
        this.deliveryTotalSent = this.deliveryTotalSent + parseInt(networks.deliveryTarget);
        this.sendmailForUser(networks)
        this.arrayToSendOffer.splice(index, 1)
        this.cdRef.detectChanges();
      })
    }
  }

  sendmailForUser(user : any){
    this.service.sendOfferEmail(user.influencerInfo.secondarymail).subscribe()
  }

  //////////////// Add and Delete Element for Filter With Generic Fct/////////////////////////////////////////////////


  verifiedIndexOf(arr : any, elem : string) : Boolean {
    let check = false
    arr.forEach((res : any) => {
     if (elem == res ){
       check = true
     }
    })
    return check
  }
  checkSpaceOnString(arr : any, str : string) {
    let tmpstr = str.split(" ")
    tmpstr.forEach((elem) =>  {
      if (elem != " "){
        let verif = this.verifiedIndexOf(arr, elem)
        if (verif == false){
          arr.push(elem)
          
        }
      }
    })
  }

  addKeyWords(){
    this.keywordTxt = document.getElementById('keywordTxt')
    if (this.keywordTxt['value']){
      this.Keywords.value.push(this.keywordTxt['value'])
      this.keywordTxt['value'] = ""
    }
  }

  findInfs(){
    this.term = this.tmp
  }

  valueToPercent (value: number) {
    return (value * 100) / this.userArray.users.length
  } 

  openOfferDetailInNewWindow(user : any) {
    if (user && user.userId){
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/detail-influencer/${user.userId}`])
      );
      window.open(url, '_blank');
    }
    
  }

  calculateT(elem: any){
    setTimeout( () => {
    if (this.campaign.saleModels === "CPM"){  
      if (elem.deliveryTarget === 0){
          elem.revenueTarget = 0;
          this.cdRef.detectChanges()
      } else {
        let tmpValue = elem.deliveryTarget * elem.prixUnitaire
        elem.revenueTarget = tmpValue / 1000;
        this.cdRef.detectChanges()
      }
    } else {
      if (elem.deliveryTarget === 0){
        elem.revenueTarget = 0;
        this.cdRef.detectChanges()
      } else {
        elem.revenueTarget = elem.deliveryTarget * elem.prixUnitaire;
        this.cdRef.detectChanges()
      }
    }
    }, 600);
    
  }

  sortByAttribute(name: string){
    this.currentUserNetworks.sort((a: any, b: any) =>  a[name] < b[name] ? -1 : 1)
  }


}
